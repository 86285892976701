import React, { useEffect, useState } from 'react'
import Sidebar from '../Components/Sidebar'
import "../css/history.css";
import { APIEndtPoint } from '../API/Apis';
import Loader from '../Components/Loader';

function History() {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const vendorId = localStorage.getItem('token');
  function formatDate(dateString) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  function formatTime(timeString) {
    const [hours, minutes] = timeString.split(':').map(Number);
    const suffix = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${suffix}`;
  }
  useEffect(() => {
    const fetchBookings = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${APIEndtPoint}/bookings/vendor_history/${vendorId}/completed`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setBookings(data.bookings);
        } else {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching bookings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, [vendorId]);

  return (
    <div style={{ height: "100%" }}>
      <Sidebar data="history" />
      <div className="main-container m-2">
        <div className="col content-container" style={{ marginTop: "20px" }}>
          <div className="d-flex position-relative justify-content-center align-items-center mb-4">
            <h4 className="fw-bold text-center w-100">History</h4>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="mb-4">
              <div className="tabs-container position-relative">
                <div
                  className="tab-indicator"
                ></div>
              </div>
              <div className="row m-2 justify-content-start">
                {bookings.length === 0 ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <p>No bookings found</p>
                  </div>
                ) : (
                  bookings &&
                  bookings.map((booking) => (
                    <div className="history-card rounded border col-lg-3 m-1 p-4" key={booking.id}>
                      <h5 className="studio-name">{booking.user.email}</h5>
                      <p className="">{booking.user.phone_number}</p>
                      <p className="location">{booking.vendor.address}</p>
                      <p className="d-flex justify-content-between"></p>
                      <div className="row mb-0 d-flex justify-content-between align-items-center">
                        <div className="col-auto">
                          <strong>Date:</strong> {formatDate(booking.time_slot.date)}
                        </div>
                        <div className="col-auto">
                          <strong>Time:</strong> {formatTime(booking.time_slot.start_time)} - {formatTime(booking.time_slot.end_time)}
                        </div>
                      </div>
                      <h6 className="mt-2">
                        <strong>Services:</strong>
                      </h6>
                      <p>
                        {booking.service_details.map((service_detail, index) => (
                          <span key={service_detail.id}>
                            {service_detail.name}
                            {index < booking.service_details.length - 1 && ', '}
                          </span>
                        ))}
                      </p>
                      <p className="total-amount">
                        <strong>Total:</strong> ₹{booking.total_payable_amount}
                      </p>
                    </div>
                  ))
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default History
