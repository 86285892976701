import React from "react";
import "../css/signUp.css";
import SignIn from "../Components/Registration/SignIn";
import SignUp from "../Components/Registration/SignUp";
// import { APIEndtPoint } from "../API/Apis";

function Registration({ closeModal, view, setView }) {
  return (
    <div className="modal">
      <div className="modal-content">
        <button className="close-btn" onClick={closeModal}>
          &times;
        </button>
        {view === "signIn" ? (
          <SignIn
            closeModal={closeModal}
            switchToSignUp={() => setView("signUp")}
          />
        ) : (
          <SignUp
            closeModal={closeModal}
            switchToSignIn={() => setView("signIn")}
          />
        )}
      </div>
    </div>
  );
}

export default Registration;
