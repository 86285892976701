import React from 'react'
import PuffLoader from "react-spinners/PuffLoader";

function Loader() {
  return (
    <div style={{ textAlign: "center", marginTop: "20%", justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
      <PuffLoader
        color="rgb(255, 149, 0)"
        cssOverride={{}}
        loading
        size={80}
        speedMultiplier={1.5}
      />
    </div>
  )
}

export default Loader