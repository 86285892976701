import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import "../css/sidebar.css";
import profileImage from "../assets/default-profile.png";
import { APIEndtPoint } from '../API/Apis';


const Sidebar = (data) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const [vendorDetails, setVendorDetails] = useState({});
  const vendor_id = localStorage.getItem('token');

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(`${APIEndtPoint}/vendors/${vendor_id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setVendorDetails(data);
        } else {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchServices();
  }, [vendor_id]);

  return (
    <>
      <button
        className="btn btn-primary d-md-none align-items-center justify-content-center d-flex"
        style={{ position: 'fixed', top: '10px', left: '10px', zIndex: '1050', height: '40px', width: '40px', backgroundColor: "rgb(255, 149, 0)", border: "rgb(255, 149, 0)", display: "flex" }}
        onClick={toggleSidebar}
      >
        <i className="bi bi-list" style={{ fontSize: '1.5rem' }}></i>
      </button>
      <div className={`d-flex flex-column flex-shrink-0 bg-light ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`} style={{ width: '250px', height: '100%', position: 'fixed', zIndex: '1040', transition: 'transform 0.3s', marginTop: '-20px' }} >
        <Link
          to="/"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none px-3 py-2 border-bottom w-100"
        >
          {/* <img
          src=""
          alt="Logo"
          className="bi pe-none me-2"
          style={{ width: '30px', height: '30px' }}
        /> 
        <span className="fs-5 fw-bold">      
          <Link to="/" style={{ textDecoration: 'none', color: '#ff9500' , fontSize: 26}}>
            TrimlyXpert
          </Link>
        </span> */}
          <span className="fs-5 fw-bold" style={{ color: '#ff9500', fontSize: '26px', marginLeft: '40px', marginTop: "11px" }}>
            TrimlyXpert
          </span>
        </Link>
        <ul className="nav nav-pills flex-column mb-auto">
          <li className="nav-item">
            <Link to="/" className="nav-link link-dark d-flex align-items-center px-3 py-2 text-decoration-none">
              <i className="bi bi-house-door me-2 "></i>
              Home
            </Link>
          </li>
          <li>
            <Link to="/dashboard" className="nav-link link-dark d-flex align-items-center px-3 py-2 text-decoration-none" style={data.data === "dashboard" ? { backgroundColor: "rgb(255, 149, 0)" } : {}}>
              <i className="bi bi-speedometer2 me-2"></i>
              Dashboard
            </Link>
          </li>
          <li>
            <Link to="/dashboard/bookings" className="nav-link link-dark d-flex align-items-center px-3 py-2 text-decoration-none" style={data.data === "booking" ? { backgroundColor: "rgb(255, 149, 0)" } : {}}>
              <i className="bi bi-calendar-event me-2"></i>
              Bookings
            </Link>
          </li>
          {/* <li>
            <Link to="/dashboard/addresses" className="nav-link link-dark d-flex align-items-center px-3 py-2 text-decoration-none" style={data.data === "address" ? { backgroundColor: "rgb(255, 149, 0)" } : {}}>
              <i className="bi bi-geo-alt me-2"></i>
              Service Address
            </Link>
          </li> */}
          <li>
            <Link to="/dashboard/services" className="nav-link link-dark d-flex align-items-center px-3 py-2 text-decoration-none" style={data.data === "services" ? { backgroundColor: "rgb(255, 149, 0)" } : {}}>
              <i className="bi bi-basket me-2"></i>
              My Services
            </Link>
          </li>
          <li>
            <Link to="/dashboard/newService" className="nav-link link-dark d-flex align-items-center px-3 py-2 text-decoration-none" style={data.data === "newService" ? { backgroundColor: "rgb(255, 149, 0)" } : {}}>
              <i className="bi bi-plus-circle me-2"></i>
              Add New Service
            </Link>
          </li>
          <li>
            <Link to="/dashboard/history" className="nav-link link-dark d-flex align-items-center px-3 py-2 text-decoration-none" style={data.data === "history" ? { backgroundColor: "rgb(255, 149, 0)" } : {}}>
              <i className="bi bi-clock-history me-2"></i>
              History
            </Link>
          </li>
          <li>
            <Link to="/dashboard/profile" className="nav-link link-dark d-flex align-items-center px-3 py-2 text-decoration-none" style={data.data === "profile" ? { backgroundColor: "rgb(255, 149, 0)" } : {}}>
              <i className="bi bi-person-circle me-2"></i>
              Profile
            </Link>
          </li>
        </ul>
        <div className="dropdown px-3 py-3 border-top">
          <Link
            to="/"
            className="d-flex align-items-center link-dark text-decoration-none dropdown-toggle"
            id="dropdownUser"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src={(vendorDetails.vendor && vendorDetails.vendor.image_url && vendorDetails.vendor.image_url.url && vendorDetails.vendor.image_url.url) || profileImage}
              alt="User Profile"
              className="rounded-circle me-2"
              style={{ width: '40px', height: '40px' }}
            />
            <strong>
              {vendorDetails.vendor && vendorDetails.vendor.name
                ? vendorDetails.vendor.name.length > 16
                  ? `${vendorDetails.vendor.name.slice(0, 16)}...`
                  : vendorDetails.vendor.name
                : ''}
            </strong>
          </Link>
          <ul className="dropdown-menu text-small" aria-labelledby="dropdown-item">
            <li>
              <Link className="dropdown-item d-flex align-items-center text-decoration-none" to="/dashboard/profile">
                <i className="bi bi-person me-2"></i>
                Profile
              </Link>
            </li>
            <li>
              <Link className="dropdown-item d-flex align-items-center text-decoration-none" to="#">
                <i className="bi bi-gear me-2"></i>
                Settings
              </Link>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <Link className="dropdown-item d-flex align-items-center text-decoration-none" to="#"
                onClick={(event) => {
                  event.preventDefault();
                  handleLogout();
                }} >
                <i className="bi bi-box-arrow-right me-2"></i>
                Sign out
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
