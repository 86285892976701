import React, { useState } from "react";
import Sidebar from "../Components/Sidebar";
import "../css/dashboard.css";
import Loader from "../Components/Loader";
import UpcomingBookings from "../Components/Dashboard/UpcomingBookings";
import PositionedSnackbar from "../Components/PositionedSnackbar";

function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);

  return (
    <div style={{ height: "100%" }}>
      <Sidebar data="dashboard" />
      <div className="main-container m-2">
        <div className="col content-container" style={{ marginTop: "20px" }}>
          <div className="d-flex position-relative justify-content-center align-items-center mb-4">
            <h4 className="fw-bold text-center w-100">Dashboard</h4>
          </div>
          <div className="mb-4">
            {loading ? (
              <Loader />
            ) : (
              <div className="main-container m-1">
                <div className="dashboard-container m-2 row g-1">
                  <UpcomingBookings
                    setLoading={setLoading}
                    setSnackbarMessage={setSnackbarMessage}
                    setShowSnackbar={setShowSnackbar}
                  />
                  <div className="dashboard-card col-lg-6 bg-light shadow h-100 ">
                    Box 2
                  </div>{" "}
                  <div className="dashboard-card col-lg-6 bg-light shadow h-100 ">
                    Box 3
                  </div>{" "}
                  <div className="dashboard-card col-lg-6 bg-light shadow h-100 ">
                    Box 4
                  </div>{" "}
                  <div className="dashboard-card col-lg-6 bg-light shadow h-100 ">
                    Box 5
                  </div>{" "}
                  <div className="dashboard-card col-lg-6 bg-light shadow h-100 ">
                    Box 6
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showSnackbar && <PositionedSnackbar message={snackbarMessage} />}
    </div>
  );
}

export default Dashboard;
