import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import "../css/bookings.css";
import { APIEndtPoint } from "../API/Apis";
import PositionedSnackbar from "../Components/PositionedSnackbar";

export default function Bookings() {
  const vendor_id = localStorage.getItem("token");
  const [selectedTab, setSelectedTab] = useState("upcoming");
  const [bookings, setBookings] = useState([]);
  const [tabIndicatorStyle, setTabIndicatorStyle] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [expandedBookings, setExpandedBookings] = useState({});

  useEffect(() => {
    const activeTab = document.querySelector(".tab-button.active");
    if (activeTab) {
      setTabIndicatorStyle({
        left: activeTab.offsetLeft,
        width: activeTab.offsetWidth,
      });
    }
  }, [selectedTab]);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await fetch(
          `${APIEndtPoint}/bookings/vendor_history/${vendor_id}/${selectedTab}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          setBookings(data.bookings);
        } else {
          console.log(data.error);
        }
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    };

    fetchBookings();
  }, [selectedTab, vendor_id, refresh]);

  const filterBookings = (status) => {
    setBookings(
      status === "All"
        ? bookings
        : bookings.filter((booking) => booking.status === status)
    );
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    filterBookings(tab);
  };

  const toPascalCase = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join("");
  };

  const toggleServices = (bookingId) => {
    setExpandedBookings((prev) => ({
      ...prev,
      [bookingId]: !prev[bookingId],
    }));
  };

  const handleSelectedBooking = async (selectedBookingId, status) => {
    try {
      const response = await fetch(
        `${APIEndtPoint}/bookings/${selectedBookingId}/update_vendor_status`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status }),
        }
      );

      if (response.ok) {
        console.log("Booking updated");
        setRefresh(!refresh);
        setSnackbarMessage(
          status === "accepted" ? "Booking Accepted!" : "Booking Rejected!"
        );
        setShowSnackbar(true);
      } else {
        const updatedBooking = await response.json();
        console.error("Error updating booking status:", updatedBooking);
      }
    } catch (error) {
      console.error("Error updating booking status:", error);
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <Sidebar data="booking" />
      <div className="main-container m-2">
        <div className="col content-container" style={{ marginTop: "20px" }}>
          <div className="d-flex justify-content-center align-items-center mb-4">
            <h4 className="fw-bold text-center w-100">Bookings</h4>
          </div>
          <div className="tabs-container position-relative">
            <div
              className="tab-indicator"
              style={{
                left: `${tabIndicatorStyle.left}px`,
                width: `${tabIndicatorStyle.width}px`,
              }}
            ></div>
            {["upcoming", "accepted", "completed", "rejected"].map((tab) => (
              <button
                key={tab}
                className={`tab-button col-lg-3 border-bottom ${
                  selectedTab === tab ? "active" : ""
                }`}
                onClick={() => handleTabClick(tab)}
              >
                {toPascalCase(tab)}
              </button>
            ))}
          </div>
          <div className="booking-list mt-4 row justify-content-start">
            {bookings.length === 0 ? (
              <div className="d-flex justify-content-center align-items-center">
                <p>No bookings found</p>
              </div>
            ) : (
              bookings.map((booking) => (
                <div key={booking.id} className="booking-card">
                  <h5 className="studio-name">
                    {booking.user.email.split("@")[0]}
                  </h5>
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="">{booking.user.email}</div>
                    </div>
                    <div>
                      <div className="location">{booking.vendor.address}</div>
                    </div>
                  </div>
                  <div className="">{booking.user.phone_number}</div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <strong>Date:</strong> {booking.time_slot.date}{" "}
                    </div>
                    <div>
                      <strong>Time:</strong> {booking.time_slot.start_time} -{" "}
                      {booking.time_slot.end_time}
                    </div>
                  </div>
                  <p
                    className="bg-light"
                    onClick={() => toggleServices(booking.id)}
                  >
                    <div className="d-flex justify-content-between">
                      <div>
                        <strong>Services:</strong>
                      </div>
                      <div>
                        <button
                          className="p-1"
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          {expandedBookings[booking.id] ? (
                            <i className="fa fa-chevron-up"></i>
                          ) : (
                            <i className="fa fa-chevron-down"></i>
                          )}
                        </button>
                      </div>
                    </div>
                    {expandedBookings[booking.id] && (
                      <>
                        <ul
                          style={{
                            listStyleType: "none",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {booking.service_details.map((service, index) => (
                            <li
                              style={{
                                listStyleType: "none",
                                padding: 0,
                                margin: 0,
                              }}
                              key={index}
                            >
                              {index + 1}. {service.name}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </p>
                  <div className="row total-amount">
                    <div className="col-lg-6">
                      <strong>Total: {booking.total_payable_amount}</strong>
                    </div>
                    {booking.vendor_status === "upcoming" && (
                      <div className="col-lg-6 d-flex justify-content-end">
                        <button
                          onClick={() =>
                            handleSelectedBooking(booking.id, "accepted")
                          }
                          className={`btn btn-success col-lg-4 mx-1 ${
                            booking.vendor_status === "upcoming"
                              ? ""
                              : "disabled"
                          }`}
                          disabled={booking.vendor_status !== "upcoming"}
                        >
                          Accept
                        </button>
                        <button
                          onClick={() =>
                            handleSelectedBooking(booking.id, "rejected")
                          }
                          className={`btn btn-danger col-lg-4 mx-1 ${
                            booking.vendor_status === "upcoming"
                              ? ""
                              : "disabled"
                          }`}
                          disabled={booking.vendor_status !== "upcoming"}
                        >
                          Reject
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      {showSnackbar && <PositionedSnackbar message={snackbarMessage} />}
    </div>
  );
}
