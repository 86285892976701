import React from "react";
import { useNavigate } from "react-router-dom";
import profileImgage from "../../assets/default-profile.png";
import Loader from "../Loader";

export default function ProfileList({ vendorDetails = {}, loading = false }) {
  const vendor = vendorDetails["vendor"] || {};
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate("/dashboard/profile/updateProfile");
  };

  return (
    <div className="mb-4 main">
      {loading ? (
        <Loader />
      ) : (
        <div className="card-profile-profile">
          <div className="edit-icon" onClick={handleEditClick}>
            <i
              className="bi bi-pencil"
              style={{
                color: "rgb(255, 149, 0)",
                fontSize: "20px",
                cursor: "pointer",
              }}
            ></i>
          </div>
          <div className="profile-image">
            <img
              src={(vendor.image_url && vendor.image_url.url) || profileImgage}
              alt="Profile Pic"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = profileImgage;
              }}
            />
          </div>
          <div className="card-profile-body">
            <table>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>:</td>
                  <td>{vendor.name || "N/A"}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>:</td>
                  <td>{vendor.email || "N/A"}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>:</td>
                  <td>{vendor.address || "N/A"}</td>
                </tr>
                <tr>
                  <td>Phone Number</td>
                  <td>:</td>
                  <td>{vendor.phone_number || "N/A"}</td>
                </tr>
                <tr>
                  <td>Currently Opened</td>
                  <td>:</td>
                  <td>{vendor.currently_opening ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>Timing</td>
                  <td>:</td>
                  <td>
                    {vendor.opening_time && vendor.closing_time
                      ? `${vendor.opening_time} - ${vendor.closing_time}`
                      : "N/A"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
