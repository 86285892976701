import React from "react";
import ServiceDetailForm from "./ServiceDetailForm";

export default function ServiceTypeForm({
  serviceTypes,
  onAddType,
  onRemoveType,
  onTypeChange,
  onAddDetail,
  onRemoveDetail,
  onDetailChange,
  selectedService,
  onSubmit,
  errors,
}) {
  return (
    <>
      <h5 className="fw-bold">
        Add Service Types for - <span>{selectedService.name}</span>
      </h5>
      <form className="mt-3 form-responsive">
        {serviceTypes.map((type, index) => (
          <div key={index} className="mb-4 border p-2 rounded">
            <div className="row position-relative">
              <h6 className="mt-3">Service Type</h6>
              {serviceTypes.length > 1 && (
                <i
                  className="bi bi-x text-danger ms-2 position-absolute desktop-only"
                  style={{
                    cursor: "pointer",
                    fontSize: "25px",
                    top: "20%",
                    width: "10px",
                  }}
                  onClick={() => onRemoveType(index)}
                />
              )}
            </div>
            <input
              type="text"
              className="form-control mb-1"
              value={type.name}
              required
              onChange={(e) => onTypeChange(index, e.target.value)}
              placeholder="Enter service type name"
            />
            <ServiceDetailForm
              service_details={type.service_details}
              onAddDetail={() => onAddDetail(index)}
              onRemoveDetail={(detailIndex) =>
                onRemoveDetail(index, detailIndex)
              }
              onDetailChange={(detailIndex, field, value) =>
                onDetailChange(index, detailIndex, field, value)
              }
            />
          </div>
        ))}
        {errors.length > 0 && (
          <div className="mt-3">
            <div style={{ color: "red", fontSize: "0.9em" }}>
              {errors.map((error, index) => (
                <div key={index}>{error}</div>
              ))}
            </div>
          </div>
        )}
        <div className="m-4 d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-primary responsive-btn"
            onClick={onSubmit}
          >
            Submit
          </button>
          <button
            type="button"
            className="btn btn-primary responsive-btn"
            onClick={onAddType}
          >
            Add Service Type
            <i className="bi bi-plus-circle ms-2"></i>
          </button>
        </div>
      </form>
    </>
  );
}
