import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import "../css/newService.css";
import ServiceTypeForm from "../Components/Services/ServiceTypeForm";
import ServiceSelector from "../Components/Services/ServiceSelector";
import { APIEndtPoint } from "../API/Apis";
import { useNavigate } from "react-router-dom";
import PositionedSnackbar from "../Components/PositionedSnackbar";

export default function NewService() {
  const [selectedService, setSelectedService] = useState(null);
  const [serviceTypes, setServiceTypes] = useState([
    { name: "", service_details: [{ name: "", price: "" }] },
  ]);

  const handleSelectService = (service) => {
    setSelectedService(service);
    setServiceTypes([{ name: "", service_details: [{ name: "", price: "" }] }]);
  };

  const handleAddServiceTypeField = () => {
    setServiceTypes([
      ...serviceTypes,
      { name: "", service_details: [{ name: "", price: "" }] },
    ]);
  };

  const handleServiceTypeChange = (index, value) => {
    const updatedServiceTypes = [...serviceTypes];
    updatedServiceTypes[index].name = value;
    setServiceTypes(updatedServiceTypes);
  };

  const handleAddServiceDetailField = (typeIndex) => {
    const updatedServiceTypes = [...serviceTypes];
    updatedServiceTypes[typeIndex].service_details.push({
      name: "",
      price: "",
    });
    setServiceTypes(updatedServiceTypes);
  };

  const handleServiceDetailChange = (typeIndex, detailIndex, field, value) => {
    const updatedServiceTypes = [...serviceTypes];
    updatedServiceTypes[typeIndex].service_details[detailIndex][field] = value;
    setServiceTypes(updatedServiceTypes);
  };

  const handleRemoveServiceDetail = (typeIndex, detailIndex) => {
    const updatedServiceTypes = [...serviceTypes];
    updatedServiceTypes[typeIndex].service_details.splice(detailIndex, 1);
    setServiceTypes(updatedServiceTypes);
  };

  const handleRemoveServiceType = (index) => {
    setServiceTypes(serviceTypes.filter((_, i) => i !== index));
  };

  const [availableServices, setAvailableServices] = useState([]);
  const vendorId = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleSubmit = async () => {
    const payload = {
      services: [
        {
          id: selectedService.id,
          service_types: serviceTypes.map((type) => ({
            name: type.name,
            service_details: type.service_details,
          })),
        },
      ],
    };

    try {
      const response = await fetch(`${APIEndtPoint}/vendors/${vendorId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        console.log("Service updated successfully!");
        setShowSnackbar(true);
        setTimeout(() => {
          navigate("/dashboard/services");
        }, 1000);
      } else {
        const errorData = await response.json();
        console.error("Error:", errorData.error);
        const errorMessages = Array.isArray(errorData.error)
          ? errorData.error
          : [errorData.error];
        setErrors(errorMessages);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while updating the service.");
    }
  };

  useEffect(() => {
    const fetchServices = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${APIEndtPoint}/services`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setAvailableServices(data.services);
        } else {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching bookings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, [vendorId]);

  return (
    <div style={{ height: "100%" }}>
      <Sidebar data="newService" />
      <div className="main-container m-2">
        <div className="col content-container" style={{ marginTop: "20px" }}>
          <div className="d-flex position-relative justify-content-center align-items-center mb-4">
            <h4 className="fw-bold text-center w-100">Add New Service</h4>
          </div>
          <ServiceSelector
            services={availableServices}
            selectedService={selectedService}
            onSelectService={handleSelectService}
            loading={loading}
          />
          {selectedService && (
            <ServiceTypeForm
              serviceTypes={serviceTypes}
              onAddType={handleAddServiceTypeField}
              onRemoveType={handleRemoveServiceType}
              onTypeChange={handleServiceTypeChange}
              onAddDetail={handleAddServiceDetailField}
              onRemoveDetail={handleRemoveServiceDetail}
              onDetailChange={handleServiceDetailChange}
              selectedService={selectedService}
              onSubmit={handleSubmit}
              errors={errors}
            />
          )}
        </div>
      </div>
      {showSnackbar && <PositionedSnackbar message={"New Services Added"} />}
    </div>
  );
}
