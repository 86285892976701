import React, { useState, useEffect } from 'react';
import "../css/profile.css";
import Sidebar from "../Components/Sidebar";
import ProfileList from "../Components/Profile/ProfileList";
import { APIEndtPoint } from '../API/Apis.jsx';

export default function VendorProfile() {
  const [vendorDetails, setVendorDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const vendor_id = localStorage.getItem('token');

  useEffect(() => {
    const fetchServices = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${APIEndtPoint}/vendors/${vendor_id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setVendorDetails(data);
        } else {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching services:", error);
      }
      finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, [vendor_id]);

  return (
    <div style={{ height: "100%" }}>
      <Sidebar data="profile" />
      <div className="main-container m-2">
        <div className="col content-container" style={{ marginTop: "20px" }}>
          <div className="d-flex position-relative justify-content-center align-items-center mb-4">
            <h4 className="fw-bold text-center w-100">Profile</h4>
          </div>
          <ProfileList vendorDetails={vendorDetails} loading={loading} />
        </div>
      </div>
    </div>
  );
}
