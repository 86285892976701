import React from "react";
import "../css/privacy.css";

function Privacy() {
  return (
    <div className="privacy-policy-container-p">
      <div className="content-wrapper-p">
        <h1 className="section-title-p">Privacy Policy</h1>
        <p className="effective-date-p">Effective Date: 17/12/2024</p>

        <p className="text-p">
          TrimlyXpert ("we", "our", "us") operates the TrimlyXpert mobile
          application and website (
          <a
            href="https://trimlyxpert.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.trimlyxpert.com
          </a>
          ). We are committed to protecting your privacy and personal data. This
          Privacy Policy outlines how we collect, use, and protect your personal
          information when you use our services.
        </p>

        <h2 className="title-p">1. Information We Collect</h2>
        <p className="text-p">
          We collect the following types of information when you use our
          services:
        </p>

        <h3 className="subtitle-p">Personal Information:</h3>
        <ul className="ul-list-p">
          <li className="list-p">Name</li>
          <li className="list-p">Email address</li>
          <li className="list-p">Phone number</li>
          <li className="list-p">Account credentials (email, password)</li>
        </ul>

        <h3 className="subtitle-p">Usage Data:</h3>
        <ul className="ul-list-p">
          <li className="list-p">Date and time of appointments</li>
          <li className="list-p">
            Service preferences (e.g., haircut style or grooming preferences)
          </li>
        </ul>

        <h2 className="title-p">1. How We Use Your Information</h2>
        <p className="text-p">
          We use the information we collect for the following purposes:
        </p>
        <ul className="ul-list-p">
          <li className="list-p">To provide and enhance our barber services</li>
          <li className="list-p">To facilitate booking appointments with barbers</li>
          <li className="list-p">To send appointment reminders and updates via SMS or email</li>
          <li className="list-p">To show nearby barbers and personalized service suggestions</li>
          <li className="list-p">To ensure compliance with legal and regulatory requirements</li>
          {/* <li className="list-p">To send push notifications (only if you consent)</li> */}
        </ul>

        <h2 className="title-p">2. Sharing Your Information</h2>
        <p className="text-p">
          We do not sell or rent your personal information to third parties.
          However, we may share your information with:
        </p>
        <ul className="ul-list-p">
          <li className="list-p">
            <strong>Service Providers:</strong> Barbers, salons, and third-party
            service providers who need the information to fulfill your service
            bookings.
          </li>
          {/* <li className="list-p">
            <strong>Third-Party Advertisers:</strong> If we show ads in our app,
            we may share non-personal, aggregated information to improve the
            effectiveness of advertising campaigns.
          </li> */}
          <li className="list-p">
            <strong>Legal Requirements:</strong> If required by law, to comply
            with legal obligations, or to protect our rights and users.
          </li>
          {/* <li className="list-p">
            <strong>Business Transfers:</strong> In case of a merger,
            acquisition, or asset sale, your personal information may be
            transferred as part of that transaction.
          </li> */}
        </ul>

        <h2 className="title-p">3. User Consent</h2>
        <p className="text-p">
          By using our services, you consent to the collection and use of your
          personal data as described in this Privacy Policy. You can withdraw
          your consent at any time by contacting us at{" "}
          <a className="link-p" href="mailto:trimlyxpert@gmail.com">TrimlyXpert@gmail.com</a>.
        </p>

        <h2 className="title-p">4. Data Portability</h2>
        <p className="text-p">
          You have the right to receive a copy of your personal data in a
          commonly used and machine-readable format. To request data
          portability, please contact us at the email address provided below.
        </p>

        <h2 className="title-p">5. Data Security</h2>
        <p className="text-p">
          We take reasonable steps to protect your personal information from
          unauthorized access or misuse.
        </p>

        <h2 className="title-p">6. Your Rights</h2>
        <p className="text-p">
          You have the following rights regarding your personal information:
        </p>
        <ul className="ul-list-p">
          <li className="list-p">Access to your personal information</li>
          <li className="list-p">Correction or updates to your personal data</li>
          <li className="list-p">
            Request for deletion of your personal data, subject to certain
            conditions
          </li>
          <li className="list-p">Withdrawal of consent for data processing where applicable</li>
        </ul>
        <p className="text-p">
          To exercise these rights, please contact us at{" "}
          <a className="link-p" href="mailto:trimlyxpert@gmail.com">TrimlyXpert@gmail.com</a>
        </p>

        <h2 className="title-p">7. Cookies and Tracking Technologies</h2>
        <p className="text-p">
          We use cookies, web beacons, and similar tracking technologies to
          enhance your user experience. Cookies are small files stored on your
          device that allow us to collect information about your activity. You
          can manage your cookie preferences through your browser settings.
        </p>

        <h2 className="title-p">8. Data Retention</h2>
        <p className="text-p">
          We retain your personal information for as long as necessary to
          fulfill the purposes outlined in this Privacy Policy, unless a longer
          retention period is required or permitted by law. If you request
          deletion of your personal data, we will remove it from our systems
          within a reasonable time frame.
        </p>

        <h2 className="title-p">9. Changes to this Privacy Policy</h2>
        <p className="text-p">
          We may update our Privacy Policy from time to time. We will notify you
          of any material changes by posting the updated policy on this page
          with a revised "Updated Date: 18/12/2024 ". Please review this Privacy
          Policy periodically to stay informed about how we are protecting your
          information.
        </p>

        <h2 className="title-p">10. Contact Us</h2>
        <p className="text-p">
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at:
        </p>
        <address className="address-p">
          Email:{" "}
          <a className="link-p" href="mailto:trimlyxpert@gmail.com">TrimlyXpert@gmail.com</a>
          <br />
          Website: <a className="link-p" href="https://trimlyxpert.com/">www.trimlyxpert.com</a>
        </address>
      </div>
    </div>
  );
}

export default Privacy;
