import React, { useState, useEffect, useCallback } from "react";
import Sidebar from "../Components/Sidebar.jsx";
import "../css/service.css";
import ConfirmationModal from "../Components/ConfirmationModal.jsx";
import ServiceSelector from "../Components/Services/ServiceSelector.jsx";
import { APIEndtPoint } from "../API/Apis.jsx";
import Loader from "../Components/Loader.jsx";
import UpdateService from "./UpdateService.jsx";
import PositionedSnackbar from "../Components/PositionedSnackbar.jsx";

export default function Services() {
  const [showModal, setShowModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedTypes, setSelectedTypes] = useState({});
  const [selectedDetails, setSelectedDetails] = useState({});
  const [services, setServices] = useState(null);
  const [loading, setLoading] = useState(false);
  const [availableServices, setAvailableServices] = useState([]);
  const vendor_id = localStorage.getItem("token");
  const [isEditService, setIsEditService] = useState(false);
  const [updateServices, setUpdateServices] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleSelectService = (service) => {
    // setSelectedService(service);
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const toggleTypeSelection = (typeId, serviceDetails) => {
    setSelectedTypes((prevSelectedTypes) => {
      if (prevSelectedTypes[typeId]) {
        const updatedDetails = { ...selectedDetails };
        serviceDetails.forEach((detail) => {
          delete updatedDetails[detail.id];
        });
        setSelectedDetails(updatedDetails);

        const updatedTypes = { ...prevSelectedTypes };
        delete updatedTypes[typeId];
        return updatedTypes;
      } else {
        setSelectedDetails((prevSelectedDetails) => {
          const updatedDetails = { ...prevSelectedDetails };
          serviceDetails.forEach((detail) => {
            updatedDetails[detail.id] = true;
          });
          return updatedDetails;
        });

        return { ...prevSelectedTypes, [typeId]: true };
      }
    });
  };

  const toggleDetailSelection = (detailId) => {
    setSelectedDetails((prevSelectedDetails) => {
      if (prevSelectedDetails[detailId]) {
        const updatedDetails = { ...prevSelectedDetails };
        delete updatedDetails[detailId];
        return updatedDetails;
      } else {
        return { ...prevSelectedDetails, [detailId]: true };
      }
    });
  };

  const fetchServices = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${APIEndtPoint}/vendors/${vendor_id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setServices(data);
      } else {
        throw new Error(`HTTP error: Status ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    } finally {
      setLoading(false);
    }
  }, [vendor_id]);

  useEffect(() => {
    fetchServices();
    setUpdateServices(false);
  }, [fetchServices, updateServices]);

  useEffect(() => {
    const fetchAvailableServices = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${APIEndtPoint}/services`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setAvailableServices(data.services);
        } else {
          throw new Error(`HTTP error: Status ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching bookings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAvailableServices();
  }, [vendor_id]);

  return (
    <>
      <div className="h-100">
        <Sidebar data={"services"} />
        <div className="main-container m-2">
          <div className="col content-container" style={{ marginTop: "20px" }}>
            <div className="d-flex position-relative justify-content-center align-items-center mb-4">
              <h4 className="fw-bold text-center w-100">
                {isEditService ? (
                  <>Update Your Service</>
                ) : (
                  <>Manage Your Services</>
                )}
              </h4>
            </div>

            {loading ? (
              <Loader />
            ) : (
              <>
                {/* Services List */}
                {isEditService ? (
                  <>
                    <ServiceSelector
                      services={availableServices}
                      selectedService={selectedService}
                      onSelectService={handleSelectService}
                    />
                    {selectedService && (
                      <UpdateService
                        selectedService={selectedService}
                        setIsEditService={setIsEditService}
                        setSnackbarMessage={setSnackbarMessage}
                        setShowSnackbar={setShowSnackbar}
                      />
                    )}
                  </>
                ) : (
                  <div className="row m-2">
                    {services &&
                      services.vendor.services.map((service) => (
                        <div
                          className="col-md-6 col-lg-3 mb-4"
                          key={service.id}
                        >
                          <div className="card service-card h-100 shadow-sm">
                            <div className="card-body d-flex flex-column rounded">
                              <h5 className="card-title text-center mb-3">
                                {service.name}
                              </h5>
                              {service.service_types.map((type) => (
                                <div
                                  key={type.id}
                                  className="mb-3 bg-light p-1"
                                >
                                  <h6 className="text-primary d-flex justify-content-between align-items-center">
                                    {type.name}
                                    <input
                                      type="checkbox"
                                      checked={!!selectedTypes[type.id]}
                                      onChange={() =>
                                        toggleTypeSelection(
                                          type.id,
                                          type.service_details
                                        )
                                      }
                                    />{" "}
                                  </h6>
                                  {type.service_details.map((detail) => (
                                    <div
                                      key={detail.id}
                                      className="d-flex justify-content-between align-items-center row"
                                    >
                                      <span className="col-md-8">
                                        {detail.name}
                                      </span>
                                      <div className="d-flex justify-content-end align-items-center col-md-4">
                                        <span className="fw-bold me-2">
                                          ₹{detail.price}
                                        </span>
                                        <input
                                          type="checkbox"
                                          checked={!!selectedDetails[detail.id]}
                                          onChange={() =>
                                            toggleDetailSelection(detail.id)
                                          }
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ))}
                              <div className="d-flex justify-content-between mt-auto">
                                <i
                                  className="bi bi-pencil"
                                  style={{
                                    color: "rgb(255, 149, 0)",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setSelectedService(service);
                                    setIsEditService(true);
                                  }}
                                ></i>
                                <i
                                  className={`bi bi-trash ${
                                    Object.keys(selectedDetails).length ||
                                    Object.keys(selectedTypes).length > 0
                                      ? ""
                                      : "pointer-events-none"
                                  }`}
                                  style={{
                                    color: "red",
                                    fontSize: "20px",
                                    cursor:
                                      Object.keys(selectedDetails).length ||
                                      Object.keys(selectedTypes).length > 0
                                        ? "pointer"
                                        : "not-allowed",
                                  }}
                                  onClick={() => {
                                    if (
                                      Object.keys(selectedDetails).length ||
                                      Object.keys(selectedTypes).length > 0
                                    ) {
                                      handleShowModal();
                                    }
                                  }}
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <ConfirmationModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedTypes={selectedTypes}
        selectedDetails={selectedDetails}
        setSelectedTypes={setSelectedTypes}
        setSelectedDetails={setSelectedDetails}
        setSnackbarMessage={setSnackbarMessage}
        setShowSnackbar={setShowSnackbar}
        setUpdateServices={setUpdateServices}
      />

      {showSnackbar && <PositionedSnackbar message={snackbarMessage} />}
    </>
  );
}
