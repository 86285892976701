import React, { useState } from 'react';
import Sidebar from '../Components/Sidebar'
import { VendorJsonData } from '../DummyJsonData/VendorJsonData';
import { useNavigate } from 'react-router-dom';

function ServiceAddress() {
  const vendor = VendorJsonData.vendor;
  const [address, setAddress] = useState(vendor.address);
  const [isEditing, setIsEditing] = useState(false);
  const [newAddress, setNewAddress] = useState("");
  const [isDeleting, setIsDeleting] = useState(false); // State for delete confirmation
  const navigate = useNavigate();

  const handleAddressChange = (event) => {
    setNewAddress(event.target.value);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setAddress(newAddress);
    // Add your API call to save the new address here
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setNewAddress(""); 
  };

  const handleDeleteClick = () => {
    setIsDeleting(true); 
  };

  const confirmDelete = () => {
    // Add your API call to delete the address here
    setAddress(""); 
    setIsDeleting(false);
  };

  const cancelDelete = () => {
    setIsDeleting(false); 
  };

  return (
    <div style={{ height: "100%" }}>
      <Sidebar data="address" />
      <div className="main-container m-2">
        <div className="col content-container" style={{ marginTop: "20px" }}>
          <div className="d-flex position-relative justify-content-center align-items-center mb-4">
            <h4 className="fw-bold text-center w-100">Manage Your Service Address</h4>
          </div>
          <div className="mb-4">
            <div className="address-container">
              <div className="d-flex justify-content-end align-items-center mb-4">
                <button
                  className="btn btn-primary"
                  onClick={() => navigate("/dashboard/profile/addServiceAddress")}
                >
                  Add New Address
                </button>
              </div>

              <div className="card">
                <div className="card-body">
                  <h5>Address:</h5>
                  {!isEditing ? (
                    <>
                      <p>{address}</p>
                      <button className="btn btn-warning" onClick={handleEditClick}>
                        Edit Address
                      </button>
                      <button className="btn btn-danger ms-2" onClick={handleDeleteClick}>
                        Delete Address
                      </button>
                    </>
                  ) : (
                    <>
                      <textarea
                        className="form-control"
                        value={newAddress}
                        onChange={handleAddressChange}
                        placeholder="Enter new address"
                        rows="4"
                      />
                      <div className="d-flex justify-content-end mt-3">
                        <button className="btn btn-secondary me-2" onClick={handleCancelClick}>
                          Cancel
                        </button>
                        <button className="btn btn-warning" onClick={handleSaveClick}>
                          Save Address
                        </button>
                      </div>
                    </>
                  )}

                  {/* Delete Confirmation Modal */}
                  {isDeleting && (
                    <div className="mt-3">
                      <div className="alert alert-warning">
                        Are you sure you want to delete this address?
                      </div>
                      <div className="d-flex justify-content-end">
                        <button className="btn btn-secondary me-2" onClick={cancelDelete}>
                          Cancel
                        </button>
                        <button className="btn btn-danger" onClick={confirmDelete}>
                          Confirm Delete
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceAddress;
