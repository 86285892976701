import React, { useState } from "react";
import "../css/header.css";
import { Link, useNavigate } from "react-router-dom";
import Registration from "../Pages/Registration";

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [view, setView] = useState("signIn");
  const [menuOpen, setMenuOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const closeMenu = () => setMenuOpen(false);
  const vendorID = localStorage.getItem("token");
  const navigate = useNavigate();
  const handleDashboardRedirect = () => {
    navigate("/dashboard");
  };

  return (
    <header className="header-h">
      <Link
        to="/"
        style={{ textDecoration: "none", color: "white", fontSize: 26 }}
      >
        TrimlyXpert
      </Link>

      <nav className={`nav-h ${menuOpen ? "open" : ""}`}>
        <Link to="/" onClick={closeMenu}>
          About Us
        </Link>
        <Link to="/features" onClick={closeMenu}>
          Features
        </Link>
        <Link to="/pricing" onClick={closeMenu}>
          Pricing
        </Link>
        <Link to="/testimonials" onClick={closeMenu}>
          Testimonials
        </Link>
        <Link to="/casestudies" onClick={closeMenu}>
          Case Studies
        </Link>
      </nav>

      <div className="auth-buttons">
        {!vendorID ? (
          <button className="open-modal-btn" onClick={openModal}>
            Sign In
          </button>
        ) : (
          <button className="open-modal-btn" onClick={handleDashboardRedirect}>
            Go to Dashboard
          </button>
        )}

        {isModalOpen && (
          <Registration closeModal={closeModal} view={view} setView={setView} />
        )}
      </div>

      {menuOpen ? (
        <button className="menu-toggle-h" onClick={toggleMenu}>
          <span
            className="close-icon"
            style={{ color: "white", fontSize: "20px" }}
          >
            ✖
          </span>
          <>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </>
        </button>
      ) : (
        <button
          className={`menu-toggle-h ${menuOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <span className="bar-h"></span>
          <span className="bar-h"></span>
          <span className="bar-h"></span>
        </button>
      )}
    </header>
  );
};

export default Header;
