import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../Sidebar'

export default function AddServiceAddress() {
  const [newAddress, setNewAddress] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setNewAddress(event.target.value);
  };

  const handleSaveClick = () => {
    if (newAddress.trim() === "") {
      alert("Please enter a valid address");
      return;
    }
    // Add your API call here to save the new address
    console.log("New address saved:", newAddress);
    navigate("/dashboard/addresses");
  };

  return (
    <div style={{ height: "100%" }}>
      <Sidebar data="address" />
      <div className="main-container m-2">
        <div className="col content-container" style={{ marginTop: "20px" }}>
          <div className="d-flex position-relative justify-content-center align-items-center mb-4">
            <h4 className="fw-bold text-center w-100">Add Service Address</h4>
          </div>
          <div className="add-address-container">
            <h4 className="fw-bold">Add New Address</h4>
            <div className="card">
              <div className="card-body">
                <textarea
                  className="form-control"
                  placeholder="Enter your new address"
                  value={newAddress}
                  onChange={handleInputChange}
                  rows="4"
                />
                <div className="d-flex justify-content-end mt-3">
                  <button
                    className="btn btn-secondary me-2"
                    onClick={() => navigate("/dashboard/addresses")}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={handleSaveClick}
                  >
                    Save Address
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
