import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIEndtPoint } from "../../API/Apis";
import ShowHidePasswordButton from "./ShowHidePasswordButton";
import PositionedSnackbar from "../PositionedSnackbar";

function SignIn({ closeModal, switchToSignUp }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(APIEndtPoint + "/vendors/sign_in", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ vendor: { ...formData } }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("token", data.id);
        setError(null);
        setShowSnackbar(true);
        setTimeout(() => {
          navigate("/dashboard");
          closeModal();
        }, 2000);
      } else {
        const errorData = await response.json();
        throw new Error(
          errorData.errors || `HTTP error: Status ${response.status}`
        );
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>
      <h2>Sign In</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="E-mail"
          required
        />
        <label htmlFor="password">Password:</label>
        <div
          className="password-input-container"
          style={{ position: "relative" }}
        >
          <input
            type={showPassword ? "text" : "password"}
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password"
            required
            style={{ paddingRight: "30px" }}
          />
          <ShowHidePasswordButton
            togglePasswordVisibility={togglePasswordVisibility}
            showPassword={showPassword}
          />
        </div>

        {error && <p className="error">{error}</p>}
        <button type="submit" className="submit-btn">
          Log In
        </button>
      </form>
      <p>
        Don't have an account?{" "}
        <span className="toggle-link" onClick={switchToSignUp}>
          Sign Up
        </span>
      </p>
      {showSnackbar && (
        <PositionedSnackbar
          message={"Welcome back! You have successfully logged in."}
        />
      )}
    </div>
  );
}

export default SignIn;
