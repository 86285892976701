export const VendorJsonData =
{
  "vendor": {
      "id": 3,
      "email": 'kamal@gmail.com',
      "name": "Glamour Studio",
      "address": "Sector 18, Noida",
      "phone_number": "9876543210",
      "status": "active",
      "currently_opening": true,
      "opening_time": "08:00",
      "closing_time": "22:30",
      "latitude": 28.57,
      "longitude": 77.33,
      "image_url": {
          "id": 3,
          "url": "http://localhost:3000/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsiZGF0YSI6MywicHVyIjoiYmxvYl9pZCJ9fQ==--9f8e46d2be548f6479aaa6b4dce25e8c626b49e1/jobdesc.png"
      },
      "services": [
          {
              "id": 1,
              "name": "Hair",
              "service_types": [
                  {
                      "id": 1,
                      "name": "Hair Spa",
                      "service_details": [
                          {
                              "id": 1,
                              "name": "Basic Spa",
                              "price": 800.0
                          },
                          {
                              "id": 2,
                              "name": "Power Dose",
                              "price": 1500.0
                          }
                      ]
                  }
              ]
          },
          {
              "id": 2,
              "name": "Treatment",
              "service_types": [
                  {
                      "id": 2,
                      "name": "Treatment",
                      "service_details": [
                          {
                              "id": 3,
                              "name": "Smoothing",
                              "price": 2500.0
                          },
                          {
                              "id": 4,
                              "name": "Keratin",
                              "price": 3000.0
                          }
                      ]
                  }
              ]
          },
          {
              "id": 3,
              "name": "Makeup",
              "service_types": [
                  {
                      "id": 3,
                      "name": "Bridal Makeup",
                      "service_details": [
                          {
                              "id": 5,
                              "name": "Mac",
                              "price": 13000.0
                          },
                          {
                              "id": 6,
                              "name": "Celebrity",
                              "price": 25000.0
                          }
                      ]
                  },
                  {
                      "id": 4,
                      "name": "Facial",
                      "service_details": [
                          {
                              "id": 7,
                              "name": "Fruit",
                              "price": 700.0
                          },
                          {
                              "id": 8,
                              "name": "Lotus Basic",
                              "price": 1000.0
                          }
                      ]
                  }
              ]
          },
          {
              "id": 4,
              "name": "Body Spa",
              "service_types": [
                  {
                      "id": 5,
                      "name": "Body Spa",
                      "service_details": [
                          {
                              "id": 9,
                              "name": "Cream Massage",
                              "price": 1000.0
                          },
                          {
                              "id": 10,
                              "name": "Body Scrub",
                              "price": 1200.0
                          }
                      ]
                  }
              ]
          },
          {
            "id": 5,
            "name": "Body Spa",
            "service_types": [
                {
                    "id": 6,
                    "name": "Body Spa",
                    "service_details": [
                        {
                            "id": 11,
                            "name": "Cream Massage",
                            "price": 1000.0
                        },
                        {
                            "id": 12,
                            "name": "Body Scrub",
                            "price": 1200.0
                        }
                    ]
                }
            ]
          },
          {
            "id": 6,
            "name": "Treatment",
            "service_types": [
                {
                    "id": 7,
                    "name": "Treatment",
                    "service_details": [
                        {
                            "id": 13,
                            "name": "Smoothing",
                            "price": 2500.0
                        },
                        {
                            "id": 14,
                            "name": "Keratin",
                            "price": 3000.0
                        }
                    ]
                }
            ]
        },
        {
            "id": 7,
            "name": "Makeup",
            "service_types": [
                {
                    "id": 8,
                    "name": "Bridal Makeup",
                    "service_details": [
                        {
                            "id": 15,
                            "name": "Mac",
                            "price": 13000.0
                        },
                        {
                            "id": 16,
                            "name": "Celebrity",
                            "price": 25000.0
                        }
                    ]
                },
                {
                    "id": 9,
                    "name": "Facial",
                    "service_details": [
                        {
                            "id": 17,
                            "name": "Fruit",
                            "price": 700.0
                        },
                        {
                            "id": 18,
                            "name": "Lotus Basic",
                            "price": 1000.0
                        }
                    ]
                }
            ]
        }
      ]
  }
}