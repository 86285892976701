import React from "react";
import Loader from "../Loader";

export default function ServiceSelector({
  services,
  selectedService,
  onSelectService,
  loading,
}) {
  return (
    <div className="mb-4">
      {loading ? (
        <Loader />
      ) : (
        <>
          <h5 className="fw-bold">Select Service</h5>
          <div className="services-container">
            {services.map((service) => (
              <div
                key={service.id}
                className={`service-box ${
                  selectedService?.id === service.id ? "selected" : ""
                }`}
                onClick={() => onSelectService(service)}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  name="service"
                  id={`service-${service.id}`}
                  value={service.name}
                  checked={selectedService?.id === service.id}
                  onChange={() => onSelectService(service)}
                  style={{ display: "none" }}
                />
                <label
                  htmlFor={`service-${service.id}`}
                  className="service-label"
                >
                  {service.name}
                </label>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
