import React from "react";
import "../css/confirmationModal.css";
import { Modal } from "react-bootstrap";
import { APIEndtPoint } from "../API/Apis";

export default function ConfirmationModal({
  showModal,
  handleCloseModal,
  selectedTypes,
  selectedDetails,
  setSelectedTypes,
  setSelectedDetails,
  setSnackbarMessage,
  setShowSnackbar,
  setUpdateServices,
}) {
  const vendorId = localStorage.getItem("token");
  const handleDelete = async () => {
    handleCloseModal();
    try {
      for (const id of Object.keys(selectedDetails)) {
        const response = await fetch(
          `${APIEndtPoint}/destroy_service_detail/${id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          setShowSnackbar(true);
          setSnackbarMessage("Services Deleted");
          setSelectedTypes({});
          setSelectedDetails({});
        } else {
          const error = await response.json();
          alert(
            `Error: ${error.message || "Failed to delete the service detail."}`
          );
        }
      }
      for (const id of Object.keys(selectedTypes)) {
        const response = await fetch(
          `${APIEndtPoint}/vendors/${vendorId}/service_types/${id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          setShowSnackbar(true);
          setSnackbarMessage("Services Deleted");
          setSelectedTypes({});
          setSelectedDetails({});
        } else {
          const error = await response.json();
          alert(
            `Error: ${error.message || "Failed to delete the service detail."}`
          );
        }
      }
    } catch (err) {
      alert(`Unexpected error: ${err.message}`);
    } finally {
      handleCloseModal();
      setUpdateServices(true);
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      backdrop={false}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete the selected services ?<strong></strong>
      </Modal.Body>
      <Modal.Footer>
        <button variant="secondary" onClick={handleCloseModal}>
          Cancel
        </button>
        <button variant="danger" onClick={handleDelete}>
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
}
