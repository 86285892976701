import React from 'react'

function ShowHidePasswordButton({ togglePasswordVisibility, showPassword }) {
  return (
    <button
      type="button"
      onClick={togglePasswordVisibility}
      style={{  
        position: "absolute",
        right: "5px",
        top: "40%",
        transform: "translateY(-50%)",
        background: "none",
        border: 'none',
        cursor: "pointer",
      }}
    >
      {showPassword ? (
        <i className="bi bi-eye-slash"></i>
      ) : (
        <i className="bi bi-eye"></i>
      )}
    </button>
  )
}

export default ShowHidePasswordButton
