import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIEndtPoint } from "../../API/Apis";

function UpcomingBookings({ setLoading, setSnackbarMessage, setShowSnackbar }) {
  const vendor_id = localStorage.getItem("token");
  const [bookings, setBookings] = useState([]);
  const navigate = useNavigate();

  const handleViewMoreClick = () => {
    navigate("/dashboard/bookings");
  };

  useEffect(() => {
    const fetchBookings = async () => {
      // setLoading(true);
      try {
        const response = await fetch(
          `${APIEndtPoint}/bookings/vendor_history/${vendor_id}/upcoming`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          setBookings(data.bookings || []);
        } else {
          console.error("Error fetching bookings:", data.error);
          return;
        }
      } catch (error) {
        console.error("Error fetching bookings:", error);
      } finally {
        // setLoading(false);
      }
    };

    fetchBookings();
  }, [vendor_id]);

  const handleSelectedBooking = async (selectedBookingId, status) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${APIEndtPoint}/bookings/${selectedBookingId}/update_vendor_status`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status }),
        }
      );

      if (response.ok) {
        console.log("Booking updated");
        setBookings((prevBookings) =>
          prevBookings.filter((booking) => booking.id !== selectedBookingId)
        );
        setSnackbarMessage(
          status === "accepted" ? "Booking Accepted!" : "Booking Rejected!"
        );
        setShowSnackbar(true);
      } else {
        const updatedBooking = await response.json();
        console.error("Error updating booking status:", updatedBooking);
        return;
      }
    } catch (error) {
      console.error("Error updating booking status:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="dashboard-card col-lg-6 bg-light shadow h-100 ">
      <div className="card-body overflow-auto m-2" style={{ height: "270px" }}>
        <h4 className="card-title mb-3">Today's Schedule</h4>
        {bookings.length > 0 ? (
          <>
            <ul className="list-group">
              {bookings &&
                bookings.map((booking) => (
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <span className="w-75">
                      <strong>
                        {booking.time_slot.start_time} -{" "}
                        {booking.time_slot.end_time}
                      </strong>
                      <p className="mb-0">
                        {booking.service_details
                          .map((service) => service.name)
                          .join(", ")}
                      </p>
                    </span>
                    <div className="ms-auto">
                      <button
                        onClick={() =>
                          handleSelectedBooking(booking.id, "accepted")
                        }
                        className={`btn btn-success btn-sm me-2 ${
                          booking.vendor_status === "upcoming" ? "" : "disabled"
                        }`}
                        disabled={booking.vendor_status !== "upcoming"}
                      >
                        ✓
                      </button>
                      <button
                        onClick={() =>
                          handleSelectedBooking(booking.id, "rejected")
                        }
                        className={`btn btn-danger btn-sm ${
                          booking.vendor_status === "upcoming" ? "" : "disabled"
                        }`}
                        disabled={booking.vendor_status !== "upcoming"}
                      >
                        X
                      </button>
                    </div>
                  </li>
                ))}
            </ul>
            <div className="text-end mt-3 mb-4">
              <button
                className="btn btn-primary btn-sm"
                onClick={handleViewMoreClick}
              >
                View More
              </button>
            </div>
          </>
        ) : (
          <li className="d-flex justify-content-center align-items-center">
            No Booking Available
          </li>
        )}
      </div>
    </div>
  );
}

export default UpcomingBookings;
