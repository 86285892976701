import React from "react";

export default function ServiceDetailForm({
  service_details,
  onAddDetail,
  onRemoveDetail,
  onDetailChange,
}) {
  return (
    <>
      {service_details.map((detail, index) => (
        <div key={index} className="mb-1 position-relative">
          {service_details.length > 1 && (
            <i
              className="bi bi-x position-absolute"
              style={{
                top: "2%",
                right: "3%",
                color: "red",
                fontSize: "22px",
                cursor: "pointer",
              }}
              onClick={() => onRemoveDetail(index)}
            ></i>
          )}
          <div className="border p-2 rounded bg-light">
            <div className="row">
              <div className="col-md-8">
                <h6 className="mt-1">Service Details</h6>
                <textarea
                  className="form-control custom-height"
                  placeholder="Enter Service Details"
                  value={detail.name}
                  onChange={(e) => {
                    onDetailChange(index, "name", e.target.value);
                  }}
                />
              </div>
              <div className="col-md-4">
                <h6 className="mt-1">Service Price</h6>
                <input
                  type="number"
                  className="form-control custom-height"
                  placeholder="Enter Service Price"
                  value={detail.price}
                  required
                  onChange={(e) => {
                    onDetailChange(index, "price", e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn btn-primary responsive-btn me-2"
          onClick={onAddDetail}
        >
          Add Another Detail
          <i className="bi bi-plus-circle ms-2"></i>
        </button>
      </div>
    </>
  );
}
